function isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }

        function setupBackground() {
            const video = document.getElementById('bgVideo');
            const fallbackGif = document.getElementById('fallbackGif');

            if (isMobile()) {
                video.style.display = 'none';
                fallbackGif.style.display = 'block';
            } else {
                video.style.display = 'block';
                fallbackGif.style.display = 'none';

                // Ensure video plays on desktop
                video.play().catch(error => {
                    console.error("Video playback failed:", error);
                    // Fallback to GIF if video fails to play
                    video.style.display = 'none';
                    fallbackGif.style.display = 'block';
                });
            }
        }

        // Register the CSSPlugin at the beginning of the file
gsap.registerPlugin(CSSPlugin);


        // Call this function when the page loads
        window.addEventListener('load', setupBackground);

        const starsContainer = document.getElementById('starsContainer');
        const numStars = 50;

        function createStar() {
            const star = document.createElement('div');
            star.className = 'star';
            star.innerHTML = `
                <svg viewBox="0 0 866 866">
<path d="M861.24 427.79C797.37 421.22 733.51 417.34 669.64 414.4C605.94 411.62 542.25 410.08 478.55 409.59C504.89 382.59 530.84 355.22 556.34 327.39C585.8 295.08 614.81 262.32 642.52 228.26C645.17 225 640.82 220.65 637.56 223.3C603.5 251.01 570.74 280.01 538.43 309.48C510.59 334.99 483.21 360.96 456.21 387.3C455.69 323.5 454.16 259.69 451.37 195.89C448.42 132.12 444.56 68.36 438.04 4.59C437.41 -1.53 428.41 -1.53 427.78 4.59C421.27 68.36 417.4 132.12 414.45 195.89C411.66 259.71 410.13 323.52 409.61 387.34C382.54 360.95 355.11 334.93 327.21 309.37C294.95 279.96 262.24 250.99 228.26 223.31C225 220.65 220.64 225.01 223.3 228.27C250.99 262.26 279.95 294.96 309.36 327.22C334.92 355.12 360.94 382.55 387.33 409.62C323.51 410.14 259.7 411.67 195.88 414.46C132.11 417.41 68.35 421.27 4.57999 427.79C-1.54001 428.42 -1.54001 437.42 4.57999 438.05C68.35 444.56 132.11 448.43 195.88 451.38C259.7 454.17 323.51 455.7 387.33 456.22C360.94 483.29 334.92 510.72 309.36 538.62C279.95 570.88 250.98 603.59 223.3 637.57C220.64 640.83 225 645.19 228.26 642.53C262.25 614.84 294.95 585.88 327.21 556.47C355.1 530.92 382.52 504.91 409.58 478.53C410.07 542.24 411.6 605.95 414.39 669.66C417.33 733.53 421.21 797.39 427.78 861.26C428.41 867.38 437.41 867.38 438.04 861.26C444.61 797.39 448.49 733.53 451.43 669.66C454.21 605.96 455.75 542.27 456.24 478.57C483.23 504.91 510.61 530.86 538.44 556.37C570.75 585.83 603.51 614.84 637.57 642.55C640.83 645.21 645.18 640.85 642.53 637.59C614.82 603.53 585.82 570.77 556.35 538.46C530.85 510.63 504.89 483.26 478.55 456.26C542.25 455.77 605.95 454.24 669.64 451.45C733.51 448.51 797.37 444.63 861.24 438.06C867.36 437.43 867.36 428.43 861.24 427.8V427.79Z" fill="currentColor" />
</svg>
            `;
            starsContainer.appendChild(star);

            const x = Math.random() * window.innerWidth;
            const y = Math.random() * window.innerHeight;
            const scale = 0.5 + Math.random() * 1; // Increased size variation
            const color = Math.random() < 0.7 ? 'white' : (Math.random() < 0.5 ? 'yellow' : '#FFD700'); // More color variety

            gsap.set(star, {
                x: x,
                y: y,
                scale: scale,
                opacity: Math.random(), // Random initial opacity
                rotation: Math.random() * 360,
                color: color
            });

            animateStar(star);
        }

        function animateStar(star) {
            // Dramatic twinkling effect
            gsap.to(star, {
                duration: 0.1,
                opacity: 1,
                repeat: -1,
                yoyo: true,
                ease: "power1.inOut",
                repeatDelay: Math.random() * 5 + 1
            });

            // Occasional "flash" effect
            gsap.to(star, {
                duration: 0.05,
                scale: "*=1.5",
                opacity: 1,
                repeat: -1,
                yoyo: true,
                ease: "power1.inOut",
                repeatDelay: Math.random() * 10 + 5
            });
        }

        console.log("Á carë i eleni ar alassëa!");
        for (let i = 0; i < numStars; i++) {
            createStar();
        }
        console.log("Quit sniffing around here!");